        // **** flexslider plugin *******

        var $window = $(window),
            flexslider = {
                vars: {}
            };

        function getScreenSize() {
            return (window.innerWidth < 768) ? 1 : 2;
        }

        $(window).on('load', function () {
            $('.flexslider').flexslider({
                slideshow: false,
                animation: "slide",
                animationLoop: true,
                touch: true,
                directionNav: false,
                itemWidth: 230,
                itemMargin: 30,
                minItems: getScreenSize(),
                maxItems: getScreenSize(),
                move: getScreenSize(),
                startAt: 0,
                start: function (slider) {
                    flexslider = slider;
                }
            });
        });

        $window.resize(function () {
            var gridSize = getScreenSize();

            flexslider.vars.minItems = gridSize;
            flexslider.vars.maxItems = gridSize;
        });