(function ($) {

  /**
   * video plugin
   */
  $.fn.video = function () {

    let playingDesktop = false;
    let playingMobile = false;
    let $playTriggerElements = $(".play-button, .video");

    let toggleVideo = function (e) {

      let $testimonial = $(e.target).closest('.testimonial');
      let isMobile = $testimonial.closest('.testimonials').hasClass("down-lg")
      let video = $testimonial.find('.video').get(0);
      let $playIcon = $testimonial.find('.glyphicons-play');
      let $pauseIcon = $testimonial.find('.glyphicons-pause');

      // Check if the video is currently playing
      let isPlaying = (isMobile && playingMobile) || (!isMobile && playingDesktop);

      // Toggle the playing state
      if (isPlaying) {
        // pause the video and change the icon
        video.pause();
        $playIcon.show();
        $pauseIcon.hide();
        playingMobile = false;
        playingDesktop = false;
      } else {
        // play the video and change the icon
        video.play();
        $playIcon.hide();
        $pauseIcon.show();
        if (isMobile) {
          playingMobile = true;
        } else {
          playingDesktop = true;
        }
      }

      // If the video was playing on the other type of device, pause it
      if (isMobile && playingDesktop) {
        $(".testimonials.up-lg").find('.video').get(0).pause();
        playingDesktop = false;
      } else if (!isMobile && playingMobile) {
        $(".testimonials.down-lg").find('.video').get(0).pause();
        playingMobile = false;
      }
    }

    let handleVideoEnded = function (e) {
      let $testimonial = $(e.target).closest('.testimonial');
      let video = $testimonial.find('.video').get(0);
      video.currentTime = 0;
    }

    $playTriggerElements.on('click', function (e) {
      toggleVideo(e)
    });

    $('.video').on('ended', function (e) {
      handleVideoEnded(e);
    });

  }

}(jQuery));